<template>
  <div class="overflow-hidden" style="padding:0px 0px; margin-top:0px; background-color: #f2f7fa; border-radius: 10px;">
    <div class="flex justify-end">
      <div class="flex pt-2 pb-2 pr-2" style="width: 800px">
        <el-select
          style="width: 80%"
          size="small"
          clearable
          v-model="selectRole"
          @change="handleSelectChange"
          placeholder="Select Role">
          <el-option
            v-for="(role, key) in rolesMaps"
            :key="key"
            :label="role"
            :value="key">
          </el-option>
        </el-select>
        <el-input
          style="width: 80%;margin-left: 8px;"
          size="small"
          :placeholder="$t('btn.search')"
          v-model="searchValue"
          @keyup.enter.native="getList"
        >
          <span slot="suffix" @click="getList">
            <svg-icon iconClass="search" />
          </span>
        </el-input>
        <el-date-picker  
          v-model="startDate"
          placeholder="Start Date"
          size="small"
          prefix-icon="el-icon-date" 
          type="datetime" 
          style="width: 90%;margin-left: 8px;"
          format="dd/MM/yyyy HH:mm:ss" 
          value-format="yyyy-MM-dd HH:mm:ss" 
          @change="handleSelectChange">
        </el-date-picker>
        <el-date-picker
          v-model="endDate"
          placeholder="End Date"
          :size="isMobileScreen?'mini':'small'" 
          prefix-icon="el-icon-date" 
          type="datetime" 
          style="width: 90%;margin-left: 8px;"
          format="dd/MM/yyyy HH:mm:ss" 
          value-format="yyyy-MM-dd HH:mm:ss" 
          @change="handleSelectChange">
        </el-date-picker>
      </div>
    </div>
    <div class="flex justify-center">
      <div class="w-full Comtable">
        <el-table
          :data="listData"
          :header-cell-style="{height: '55px',background: '#fff','border-bottom': '2px solid #D6D9E1',}"
          :cell-style="getCellStyle"
          table-layout="auto"
          height="100%"
        >
          <el-table-column align="left" prop="userName" min-width='100px' label="Username" />
          <el-table-column align="left" prop="userEmailAddress" min-width='100px' label="Email Address"/>
          <el-table-column align="left" prop="role" min-width='100px' label="Role" />
          <el-table-column align="left" prop="action" min-width='150px' label="Action"></el-table-column>
          <el-table-column align="left" prop="createTime" min-width='100px' label="Datetime">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="barging-pagination">
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="pageParams.pageNum"
        :limit.sync="pageParams.pageSize"
        @pagination="getList"
      ></pagination>
    </div>
  </div>
</template>

<script>
import tabs from "@/components/tabs.vue";
import { getAuditLogList } from "@/services";
import { colorToComponents } from "pdf-lib";
const rolesMap = {
  0: "System Admin",
  1: "Terminal Logistics",
  2: "Terminal Loading Master",
  3: "Terminal Leader",
  4: "Terminal CCR",
  5: "Customer Admin",
  6: "Customer User",
  7: "Barge Operator",
  8: "Barge",
  9: "Head of Pilot",
  10: "Pilot",
  11: "Surveyor Admin",
  12: "Surveyor",
};
export default {
  name: "requests",
  components: { tabs },

  data() {
    return {
      listData: [],
      searchValue: "",
      options: [
        {
          value: "1",
          label: "Today",
        },
        {
          value: "2",
          label: "ThisWeek",
        },
        {
          value: "3",
          label: "ThisMonth",
        },
        {
          value: "4",
          label: "ThisYear",
        },
      ],
      selectRole:"",
      rolesMaps: rolesMap,
      selectDate: "",
      startDate: "",
      endDate: "",
      pageParams: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      operationData: {
        src: "",
        show: false,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getCellStyle({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return {
          backgroundColor: "rgba(255,255,255,0.2)",
        };
      } else {
        return {
          backgroundColor: "rgba(228,228,228,0.2)",
        };
      }
    },
    handleSelectChange() {
      this.getList();
    },
    getList() {
      let data = {
        ...this.pageParams,
      };
      if (this.selectRole) {
        data["role"] = this.selectRole;
      }
      if (this.searchValue) {
        data["searchValue"] = this.searchValue;
        // this.searchValue = "";
      }
      if (this.startDate) {
        data["startDate"] = this.startDate;
      }
      if (this.endDate) {
        data["endDate"] = this.endDate;
      }

      getAuditLogList(data).then((res) => {
        this.listData = res.rows.map((item) => {
          return {
            ...item,
            role: rolesMap[item.role],
          };
        });
        this.total = res.total;
      });
    },
    getCellStyle({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return {
          backgroundColor: "rgba(255,255,255,0.2)",
        };
      } else {
        return {
          backgroundColor: "rgba(228,228,228,0.2)",
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.Topinp {
  position: relative;
}
.Topinput {
  display: flex;
  position: absolute;
  top: -65px;
  right: -40px;
  .Comipt {
    width: 275px;
    height: 46px;
  }
  .Pendingselect {
    width: 221px;
    height: 46px;
    margin-left: 16px;
  }
}
.Reqcol {
  display: flex;
  justify-content: center;
  height: calc(90vh - 80px - 32 * 2px - 56px);
  overflow-y: scroll;
  .ReqTable {
    width: 1000px;
  }
  .el-button--default {
    color: #fff;
    background-color: #008CDB;
  }
  .el-button--default.is-plain:hover {
    color: #fff;
    background: #008CDB;
    border-color: #008CDB;
  }
  .el-button--default:focus {
    color: #fff !important;
    background: #008CDB !important;
    border-color: #008CDB;
  }
}
::v-deep {
  .el-input__icon.el-icon-search {
    height: 32px !important;
  }
}
::v-deep .pagination-container {
  background: #f2f7fa;
}
::v-deep .el-input__suffix-inner {
  padding-right: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}
::v-deep .pagination-container {
  padding: 5px 0 5px 0;
}
::v-deep .Comtable .el-table div{
  word-break: break-word !important;
}
@media (max-width: 1023px) {
  .Comtable {
    height: calc(100vh - 175px);
  }
  .mobile-view{
    .Comtable {
      height: calc(100vh - 115px);
    }
  }
}
@media (min-width: 1024px) {
  .Comtable {
    height: calc(100vh - 180px);
  }
}
</style>
